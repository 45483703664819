import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../lib/axio";


export const checkAuth = createAsyncThunk('/checkauth', async (_, { rejectWithValue }) => {
    try {
        const response = await axiosInstance('/me');
        return response;
    } catch (error) {
        console.log('check auth slice :', error);
        return rejectWithValue(error);
    }
})


export const signupUser = createAsyncThunk('auth/signup', async (signUpCred, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post('/signup', signUpCred);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const loginUser = createAsyncThunk('auth/login', async (loginCred, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post('/login', loginCred);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const logoutUser = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post('/logout');
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
})


const initialState = {
    isAuthenticated: false,
    currentUser: null,
    error: null,
    loading: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(checkAuth.fulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.currentUser = action.payload.data;
                state.loading = false;
                state.error = null;
            })
            .addCase(checkAuth.pending, (state) => {
                state.loading = true;
            })
            .addCase(checkAuth.rejected, (state, action) => {
                state.isAuthenticated = false;
                state.currentUser = null;
                state.error = action.payload;
                state.loading = null;
            })
            .addCase(signupUser.fulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.currentUser = action.payload;
                state.error = null;
                state.loading = null;
            })
            .addCase(signupUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(signupUser.rejected, (state, action) => {
                state.isAuthenticated = false;
                state.currentUser = null;
                state.error = action.payload;
                state.loading = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.currentUser = action.payload;
                state.error = null;
                state.loading = null;
            })
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isAuthenticated = false;
                state.currentUser = null;
                state.error = action.payload;
                state.loading = null;
            })
            .addCase(logoutUser.fulfilled, (state) => {
                state.isAuthenticated = false;
                state.currentUser = null;
                state.error = null;
                state.loading = false;
            })
            .addCase(logoutUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(logoutUser.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = null;
            })

    }
})

export const { modeToggle, setMode } = authSlice.actions;

export default authSlice.reducer