import React, { useState, useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import FontFamily from '@tiptap/extension-font-family';
import TextAlign from '@tiptap/extension-text-align';
import FontSize from '@tiptap/extension-font-size';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import Mention from '@tiptap/extension-mention';
import { Node, mergeAttributes } from '@tiptap/core';

// Lucide icons for menu actions
import {
  Bold,
  Italic,
  Underline as UnderlineIcon,
  Strikethrough,
  Heading1,
  Heading2,
  List,
  Quote,
  Undo,
  Redo,
  Palette,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Minus,
  ListOrdered,
  Code,
  Link2 as LinkIcon,
  Image as ImageIcon,
  Video as VideoIcon,
  Code2 as CodeBlockIcon,
  Table as TableIcon,
  Save,
  Eye,
} from 'lucide-react';

const FONT_SIZES = [
  8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48, 56, 64, 72, 80, 88, 96, 100,
];

// --- Custom Video Extension ---
const Video = Node.create({
  name: 'video',
  group: 'block',
  inline: false,
  atom: true,
  addAttributes() {
    return {
      src: { default: null },
      width: { default: '100%' },
      height: { default: '360' },
    };
  },
  parseHTML() {
    return [{ tag: 'iframe' }];
  },
  renderHTML({ HTMLAttributes }) {
    return ['iframe', mergeAttributes(HTMLAttributes, { frameborder: 0, allowfullscreen: 'true' })];
  },
  addCommands() {
    return {
      setVideo:
        (options) =>
          ({ commands }) => {
            return commands.insertContent({
              type: this.name,
              attrs: {
                src: options.src,
                width: options.width || '100%',
                height: options.height || '360',
              },
            });
          },
    };
  },
});

// --- MenuBar Component ---
const MenuBar = ({ editor, onTogglePreview, onSaveDraft }) => {
  if (!editor) return null;

  const addLink = () => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('Enter the URL', previousUrl);
    if (url === null) return;
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  };

  const addImage = () => {
    const url = window.prompt('Enter the image URL');
    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  };

  const addVideo = () => {
    const url = window.prompt('Enter the video URL (embed link)');
    if (url) {
      editor.chain().focus().setVideo({ src: url }).run();
    }
  };

  const addCodeBlock = () => {
    editor.chain().focus().toggleCodeBlock().run();
  };

  const addTable = () => {
    // Insert a 3x3 table with a header row
    editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
  };

  return (
    <div className="border-b border-gray-700 p-4 space-y-2 bg-gray-800">
      <div className="flex flex-wrap gap-2">
        {/* Basic Text Formatting */}
        <button onClick={() => editor.chain().focus().toggleBold().run()} className={`p-2 rounded hover:bg-gray-700 ${editor.isActive('bold') ? 'bg-gray-700' : ''}`} title="Bold">
          <Bold className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={() => editor.chain().focus().toggleItalic().run()} className={`p-2 rounded hover:bg-gray-700 ${editor.isActive('italic') ? 'bg-gray-700' : ''}`} title="Italic">
          <Italic className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={() => editor.chain().focus().toggleUnderline().run()} className={`p-2 rounded hover:bg-gray-700 ${editor.isActive('underline') ? 'bg-gray-700' : ''}`} title="Underline">
          <UnderlineIcon className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={() => editor.chain().focus().toggleStrike().run()} className={`p-2 rounded hover:bg-gray-700 ${editor.isActive('strike') ? 'bg-gray-700' : ''}`} title="Strikethrough">
          <Strikethrough className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={() => editor.chain().focus().toggleCode().run()} className={`p-2 rounded hover:bg-gray-700 ${editor.isActive('code') ? 'bg-gray-700' : ''}`} title="Inline Code">
          <Code className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={addLink} className={`p-2 rounded hover:bg-gray-700 ${editor.isActive('link') ? 'bg-gray-700' : ''}`} title="Link">
          <LinkIcon className="w-5 h-5 text-gray-200" />
        </button>
        <div className="w-px h-6 bg-gray-600 mx-2" />
        {/* Headings & Lists */}
        <button onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className={`p-2 rounded hover:bg-gray-700 ${editor.isActive('heading', { level: 1 }) ? 'bg-gray-700' : ''}`} title="Heading 1">
          <Heading1 className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className={`p-2 rounded hover:bg-gray-700 ${editor.isActive('heading', { level: 2 }) ? 'bg-gray-700' : ''}`} title="Heading 2">
          <Heading2 className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={() => editor.chain().focus().toggleBulletList().run()} className={`p-2 rounded hover:bg-gray-700 ${editor.isActive('bulletList') ? 'bg-gray-700' : ''}`} title="Bullet List">
          <List className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={() => editor.chain().focus().toggleOrderedList().run()} className={`p-2 rounded hover:bg-gray-700 ${editor.isActive('orderedList') ? 'bg-gray-700' : ''}`} title="Ordered List">
          <ListOrdered className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={() => editor.chain().focus().toggleBlockquote().run()} className={`p-2 rounded hover:bg-gray-700 ${editor.isActive('blockquote') ? 'bg-gray-700' : ''}`} title="Quote">
          <Quote className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={() => editor.chain().focus().setHorizontalRule().run()} className="p-2 rounded hover:bg-gray-700" title="Horizontal Rule">
          <Minus className="w-5 h-5 text-gray-200" />
        </button>
        <div className="w-px h-6 bg-gray-600 mx-2" />
        {/* Text Alignment */}
        <button onClick={() => editor.chain().focus().setTextAlign('left').run()} className={`p-2 rounded hover:bg-gray-700 ${editor.isActive({ textAlign: 'left' }) ? 'bg-gray-700' : ''}`} title="Align Left">
          <AlignLeft className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={() => editor.chain().focus().setTextAlign('center').run()} className={`p-2 rounded hover:bg-gray-700 ${editor.isActive({ textAlign: 'center' }) ? 'bg-gray-700' : ''}`} title="Align Center">
          <AlignCenter className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={() => editor.chain().focus().setTextAlign('right').run()} className={`p-2 rounded hover:bg-gray-700 ${editor.isActive({ textAlign: 'right' }) ? 'bg-gray-700' : ''}`} title="Align Right">
          <AlignRight className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={() => editor.chain().focus().setTextAlign('justify').run()} className={`p-2 rounded hover:bg-gray-700 ${editor.isActive({ textAlign: 'justify' }) ? 'bg-gray-700' : ''}`} title="Justify">
          <AlignJustify className="w-5 h-5 text-gray-200" />
        </button>
        <div className="w-px h-6 bg-gray-600 mx-2" />
        {/* Undo/Redo */}
        <button onClick={() => editor.chain().focus().undo().run()} disabled={!editor.can().undo()} className="p-2 rounded hover:bg-gray-700 disabled:opacity-50" title="Undo">
          <Undo className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={() => editor.chain().focus().redo().run()} disabled={!editor.can().redo()} className="p-2 rounded hover:bg-gray-700 disabled:opacity-50" title="Redo">
          <Redo className="w-5 h-5 text-gray-200" />
        </button>
      </div>
      <div className="flex flex-wrap gap-2 mt-2">
        {/* Media & Advanced Elements */}
        <button onClick={addImage} className="p-2 rounded hover:bg-gray-700" title="Insert Image">
          <ImageIcon className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={addVideo} className="p-2 rounded hover:bg-gray-700" title="Insert Video">
          <VideoIcon className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={addCodeBlock} className="p-2 rounded hover:bg-gray-700" title="Code Block">
          <CodeBlockIcon className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={addTable} className="p-2 rounded hover:bg-gray-700" title="Insert Table">
          <TableIcon className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={onSaveDraft} className="p-2 rounded hover:bg-gray-700" title="Save Draft">
          <Save className="w-5 h-5 text-gray-200" />
        </button>
        <button onClick={onTogglePreview} className="p-2 rounded hover:bg-gray-700" title="Toggle Preview">
          <Eye className="w-5 h-5 text-gray-200" />
        </button>
      </div>
      <div className="flex flex-wrap items-center gap-4 pt-2 border-t border-gray-600">
        <select onChange={(e) => editor.chain().focus().setFontFamily(e.target.value).run()} className="p-2 rounded border border-gray-700 bg-gray-800 text-gray-200" value={editor.getAttributes('textStyle').fontFamily || ''}>
          <option value="">Default Font</option>
          <option value="Inter">Inter</option>
          <option value="Arial">Arial</option>
          <option value="Georgia">Georgia</option>
          <option value="Times New Roman">Times New Roman</option>
          <option value="Courier New">Courier New</option>
          <option value="Verdana">Verdana</option>
        </select>
        <select onChange={(e) => editor.chain().focus().setFontSize(e.target.value ? `${e.target.value}px` : '').run()} className="p-2 rounded border border-gray-700 bg-gray-800 text-gray-200" value={editor.getAttributes('textStyle').fontSize?.replace('px', '') || ''}>
          <option value="">Default Size</option>
          {FONT_SIZES.map((size) => (
            <option key={size} value={size}>
              {size}px
            </option>
          ))}
        </select>
        <div className="flex items-center gap-2">
          <Palette className="w-5 h-5 text-gray-200" />
          <input type="color" onInput={(e) => editor.chain().focus().setColor(e.currentTarget.value).run()} value={editor.getAttributes('textStyle').color || '#ffffff'} className="w-8 h-8" />
        </div>
      </div>
    </div>
  );
};

// --- RichEditor Component ---
export default function RichEditor({ content, onUpdate }) {
  const [isPreview, setIsPreview] = useState(false);
  const [savedDraft, setSavedDraft] = useState(null);

  // Load draft from localStorage on mount
  useEffect(() => {
    const draft = localStorage.getItem('draftContent');
    if (draft) {
      setSavedDraft(draft);
    }
  }, []);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({ heading: { levels: [1, 2, 3] } }),
      Underline,
      TextStyle,
      Color.configure({ types: ['textStyle'] }),
      FontFamily.configure({ types: ['textStyle'] }),
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      FontSize.configure({ types: ['textStyle'] }),
      Link.configure({
        openOnClick: false,
        linkOnPaste: true,
        autolink: true,
        HTMLAttributes: {
          class: 'text-blue-500 underline',
        },
      }),
      Image,
      Video,
      Table.configure({ resizable: true }),
      TableRow,
      TableHeader,
      TableCell,
      Mention.configure({
        HTMLAttributes: { class: 'mention' },
        suggestion: {
          char: '@',
          startOfLine: false,
          items: query => {
            return [
              { id: 1, label: 'John Doe' },
              { id: 2, label: 'Jane Smith' },
            ].filter(item => item.label.toLowerCase().includes(query.toLowerCase()));
          },
        },
      }),
    ],
    content: savedDraft || content || '',
    onUpdate: ({ editor }) => {
      onUpdate?.(editor.getHTML());
      localStorage.setItem('draftContent', editor.getHTML());
    },
  });

  const handleTogglePreview = () => setIsPreview(!isPreview);
  const handleSaveDraft = () => {
    if (editor) {
      localStorage.setItem('draftContent', editor.getHTML());
      alert('Draft saved!');
    }
  };

  return (
    <div className="w-full border border-gray-700 rounded-lg overflow-hidden bg-gray-900">
      <MenuBar editor={editor} onTogglePreview={handleTogglePreview} onSaveDraft={handleSaveDraft} />
      {isPreview ? (
        <div className="p-4 text-gray-200" dangerouslySetInnerHTML={{ __html: editor.getHTML() }} />
      ) : (
        <EditorContent editor={editor} className="prose max-w-none p-4 text-gray-200" />
      )}
    </div>
  );
}
