import React from 'react'
import '../../styles/about.css'

const f1Icon = "https://res.cloudinary.com/devw55qwa/image/upload/v1740029060/bulb-svgrepo-com_2_teglwj.png"
const f2Icon = "https://res.cloudinary.com/devw55qwa/image/upload/v1740029072/Frame_32_xo9zqf.png"
const f3Icon = "https://res.cloudinary.com/devw55qwa/image/upload/v1740029053/growth-improve-productivity-svgrepo-com_2_y6bh33.png"
const f4Icon = "https://res.cloudinary.com/devw55qwa/image/upload/v1740029067/quality-certificate-award-svgrepo-com_2_dhwswk.png"


const MissionSection = () => {
    return (
        <div className="min-h-screen bg-black text-white  md:px-20 noto-sans-font">
            <div className="">
                <div className="flex flex-col lg:flex-row gap-16 h-[100vh] items-center">
                    {/* Left Side - Mission Content */}
                    <div className="lg:w-1/2 flex flex-col justify-center items-center">
                        <h2 className="flex flex-col text-center dm-sans-font">
                            <span className="md:font-bold md:text-[15vh] text-[10vh] text-[#FF926A] opacity-20 ">Mission</span>
                            <span className=" text-[#FE5416] md:-translate-y-9 -translate-y-[9vh] md:font-semibold text-[7.5vh]">
                                Mission
                            </span>
                        </h2>

                        <div className="md:max-w-xl px-3 md:px-0">
                            <p className="md:text-[2.7vh] text-gray-300 leading-[1.5]">
                                At Zenith Studio, we don't merely create websites—we design digital experiences that
                                reflect your brand's character. From streamlined corporate websites to engaging
                                e-commerce sites and thorough makeovers, our strategic consultations guarantee your
                                online presence makes a lasting impression.
                            </p>
                        </div>
                    </div>

                    {/* Right Side - Feature Cards Grid */}
                    <div className="md:w-[60%]">
                        <div className="md:flex flex-col hidden">
                            <div className='md:flex items-end  '>

                                {/* Feature 1 */}
                                <div className="bg-black p-8 mission-cards rounded-lg border relative  border-black  w-[35%] h-[36vh] flex  items-end">
                                    <img src={f1Icon} alt="" className='h-[5vh] absolute right-[2vw] top-[4vh]' />
                                    <p className="text-gray-300 text-[1.8vh]">
                                        We push creative and technological boundaries to deliver cutting-edge solutions. Bold
                                        experimentation drives digital transformation and keeps you ahead of the curve.
                                    </p>
                                </div>

                                {/* Feature 2 */}
                                <div className="bg-black p-8 rounded-lg border border-black  mission-cards w-[38%] h-[30vh] relative left-[2vw] flex  items-end">
                                    <img src={f2Icon} alt="" className='h-[5vh] absolute right-[2vw] top-[4vh]' />
                                    <p className="text-gray-300 text-[1.8vh]">
                                        Scalable, flexible designs adapt to emerging technologies and industry trends.
                                        Continuous learning keeps us at the forefront of digital innovation.
                                    </p>
                                </div>
                            </div>

                            <div className='flex relative top-[2vw]'>
 
                                {/* Feature 3 */}
                                <div className="bg-black border-black mission-cards p-8 rounded-lg border  w-[38%] h-[30vh] relative right-[1.3vw] flex  items-end">
                                    <img src={f3Icon} alt="" className='h-[5vh] absolute right-[2vw] top-[4vh]' />
                                    <p className="text-gray-300 text-[1.8vh]">
                                        We build long-term partnerships with tailored solutions for sustained business growth.
                                        Ongoing support ensures your digital presence evolves with changing needs.
                                    </p>
                                </div>

                                {/* Feature 4 */}
                                <div className="bg-black border-black mission-cards p-8 rounded-lg border  w-[35%] h-[36vh] relative left-[0.6vw] flex  items-end">
                                    <img src={f4Icon} alt="" className='h-[5vh] absolute right-[2vw] top-[4vh]' />
                                    <p className="text-gray-300 text-[1.8vh]">
                                        Every product exceeds industry standards in design, functionality, and performance.
                                        Rigorous testing ensures seamless, efficient, and reliable user experiences.
                                    </p>
                                </div>

                            </div>

                        </div>
                        {/* Mobile View */}
                        <div className="md:hidden max-w-[500px]">
                            <div className="overflow-x-auto flex  w-screen  scrollbar-hide ">
                                <div className=" px-[6vw] pb-[12vh]">
                                    <div className="bg-black p-8 mission-cards rounded-lg border relative  border-black  h-[35vh] w-[70vw]  flex  items-end">
                                        <img src={f1Icon} alt="" className='h-[5vh] absolute left-[7vw] top-[3vh]' />
                                        <p className="text-gray-300 text-[1.8vh]">
                                            We push creative and technological boundaries to deliver cutting-edge solutions. Bold
                                            experimentation drives digital transformation and keeps you ahead of the curve.
                                        </p>
                                    </div>
                                </div>
                                <div className="px-[6vw] pb-[12vh]">
                                    <div className="bg-black p-8 rounded-lg border border-black  mission-cards h-[35vh] w-[70vw] relative  flex  items-end">
                                        <img src={f2Icon} alt="" className='h-[5vh] absolute left-[7vw] top-[3vh]' />
                                        <p className="text-gray-300 text-[1.8vh]">
                                            Scalable, flexible designs adapt to emerging technologies and industry trends.
                                            Continuous learning keeps us at the forefront of digital innovation.
                                        </p>
                                    </div>
                                </div>
                                <div className="px-[6vw] pb-[12vh]">
                                    <div className="bg-black border-black mission-cards p-8 rounded-lg border  h-[35vh] w-[70vw] relative  flex  items-end">
                                        <img src={f3Icon} alt="" className='h-[5vh] absolute left-[7vw] top-[3vh]' />
                                        <p className="text-gray-300 text-[1.8vh]">
                                            We build long-term partnerships with tailored solutions for sustained business growth.
                                            Ongoing support ensures your digital presence evolves with changing needs.
                                        </p>
                                    </div>
                                </div>
                                <div className="px-[6vw] pb-[12vh]">
                                    <div className="bg-black border-black mission-cards p-8 rounded-lg border  h-[35vh] w-[70vw] relative  flex  items-end">
                                        <img src={f4Icon} alt="" className='h-[5vh] absolute left-[7vw] top-[3vh]' />
                                        <p className="text-gray-300 text-[1.8vh]">
                                            Every product exceeds industry standards in design, functionality, and performance.
                                            Rigorous testing ensures seamless, efficient, and reliable user experiences.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MissionSection
