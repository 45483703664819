import About from "../component/home/AboutPage"
import Carousel from "../component/home/Carousel"
import HeroSection from "../component/home/HeroSection"
import Peak from "../component/home/Peak"
import Process from "../component/home/Process"
import ServicesPage from "../component/home/ServicePage"
import '../styles/about.css'


const HomePage = () => {

  return (
    <div className="max-w-[100vw] min-w-[100vw] overflow-hidden ">
      <HeroSection />
      <About />
      <ServicesPage />
      <Process />
      <Carousel />
      <Peak />
    </div>

  )
}

export default HomePage
