import { Users } from 'lucide-react';
import React from 'react'
import '../../styles/about.css'

const Team = () => {
    const teamMembers = [
        {
            name: 'Kowdi Vaishnavi',
            role: 'Co-Founder - Zenith Studio',
            image: 'https://res.cloudinary.com/devw55qwa/image/upload/v1740638398/IMG_20250227_113300_dulk1c.jpg',
            description: [
                'B.Tech graduate, co-founded Zenith Studio to redefine digital excellence with innovation in website design, UI/UX, and development.',
                'What started as a dream with a friend has become a bold reality, setting new benchmarks in the industry.',
                'At Zenith, she leads with precision, passion, and an unwavering dedication to delivering cutting-edge digital solutions.'
            ]
        },
        {
            name: 'Arakanti Durga Prashanthi',
            role: 'Co-Founder - Zenith Studio',
            image: 'https://res.cloudinary.com/devw55qwa/image/upload/v1740638140/IMG-20250227-WA0103_mldi7q.jpg',
            description: [
                'With a BTech in Mechanical Engineering, took a unique path, co-founding Zenith Studio alongside two friends who shared a vision for innovation.',
                'What began as a simple meeting turned into a mission to support local startups and businesses by providing cutting-edge digital solutions.',
                'Recognizing the challenges small businesses face in establishing an online presence, they created Zenith Studio as a platform to help them succeed.',
                'Driven by problem-solving, creativity, and a passion for seamless user experiences, Prashanthi ensures that Zenith Studio delivers high-quality website design, UI/UX, and development, empowering businesses to grow in the digital world.'
            ]
        }
    ];


    const teams = [
        {
            image: "https://res.cloudinary.com/devw55qwa/image/upload/v1740638854/IMG_2668_1_sqf5kv.jpg",
            name: "Nithin Kumar Meena",
            role: "Full-Stack Developer"
        },
        {
            image: "https://res.cloudinary.com/devw55qwa/image/upload/v1740638151/I_m_A_19-Year-Old_Turkish_Guy_Who_Turns_His_Dreams_Into_Pictures_48_Pics_2024_jdczu0.jpg",
            name: "Yogini Navghare",
            role: "UI/UX Designer"
        }
    ]

    return (
        <div id='team' className=" noto-sans-font min-h-screen bg-black text-white py-20 md:px-20 min-w-full  flex flex-col justify-center items-center">
            <div className="md:max-w-7xl">
                <div className="text-center mb-16">
                    <h2 className=" text-4xl md:text-5xl   flex flex-col text-center dm-sans-font">
                        <span className="md:font-bold text-[8vh] md:text-[12vh] text-[#FF926A] opacity-20 ">Our Team</span>
                        <span className=" text-[#FE5416] text-[6vh] md:-translate-y-12 md:font-semibold md:text-[6.5vh]">
                            Team
                        </span>
                    </h2>
                </div>

                <div className="space-y-24">
                    {teamMembers.map((member, index) => (
                        <div
                            key={member.name}
                            className={`flex flex-col lg:flex-row gap-8 lg:gap-16 items-center ${index % 2 === 1 ? 'lg:flex-row-reverse' : ''
                                }`}
                        >
                            <div className={`team-about-img md:w-full lg:w-1/2 ${index % 2 === 1 ? 'rotate-180' : ''}`}>
                                <img
                                    src={member.image}
                                    alt={member.name}
                                    className={`md:w-full  md:h-[500px] object-cover shadow-2xl ${index % 2 === 1 ? 'rotate-180' : ''}`}
                                />
                            </div>

                            <div className="w-full lg:w-1/2 space-y-6 md:space-x-0 space-x-[15vw]">
                                <h3 className="text-[5.5vh] text-center md:text-start font-semibold dm-sans-font">{member.name}</h3>
                                <p className="text-[#FE5416] text-[2.5vh] font-semibold dm-sans-font">{member.role}</p>
                                <ul className="space-y-4 list-disc pl-[2vw]">
                                    {member.description.map((desc, i) => (
                                        <li key={i} className="text-gray-300 leading-relaxed">
                                            {desc}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='flex justify-evenly flex-col md:flex-row items-center w-full md:py-[20vh] md:space-y-0 space-y-14 md:mt-0 mt-[10vh]'>
                    {teams.map((member) => (
                        <div className='flex  flex-col md:space-x-0 items-center '>
                            <img src={member.image} alt="" className='md:h-[42vh] md:w-[16vw] w-3/4 md:rounded-3xl' />
                            <h3 className='text-[2vh] dm-sans-font' >{member.name}</h3>
                            <h4 className='text-[1.5vh]'>{member.role}</h4>
                        </ div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Team
