import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messages: [],
  isTyping: false,
  context: {
    leadCaptured: false,
    conversationStage: 'initial',
  },
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    setTyping: (state, action) => {
      state.isTyping = action.payload;
    },
    updateContext: (state, action) => {
      state.context = { ...state.context, ...action.payload };
    },
    captureLead: (state, action) => {
      state.context.leadCaptured = true;
      state.context.userName = action.payload.name;
      state.context.userEmail = action.payload.email;
      state.context.interestedService = action.payload.interestedService;
    },
    resetChat: (state) => {
      state.messages = [];
      state.context = {
        leadCaptured: false,
        conversationStage: 'initial',
      };
    },
  },
});

export const { addMessage, setTyping, updateContext, captureLead, resetChat } = chatSlice.actions;
export default chatSlice.reducer;
