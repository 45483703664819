import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8 no-splash noto-sans-font md:flex md:justify-center  w-screen">
      <div className="w-[100vw] px-[5vw]">
        {/* Top Section with 4 "columns" (becomes 1 col on mobile) */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

          {/* Logo + Contact Info */}
          <div className="flex flex-col items-center md:items-start">
            <Link to="/">
              <img
                className="md:h-20 mb-4"
                src="https://res.cloudinary.com/devw55qwa/image/upload/v1739528292/logo_dx8qir.png"
                alt="zenith logo"
              />
            </Link>
            <div className="text-base text-center md:text-left">
              <h3 className="mb-2 md:font-semibold">Contact Info</h3>
              <p>Email: business@zenithstudio.in</p>
            </div>
          </div>

          <div className='flex justify-evenly'>
            {/* Navigation Links */}
            <div className="flex flex-col items-center md:items-start">
              <ul className="leading-8 text-center md:text-left">
                <li>
                  <Link to="/" className="hover:text-[#FE5416]">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="hover:text-[#FE5416]">
                    About us
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="hover:text-[#FE5416]">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-[#FE5416]">
                    Work
                  </Link>
                </li>
                <li>
                  <Link to="/blog" className="hover:text-[#FE5416]">
                    Blogs
                  </Link>
                </li>
              </ul>
            </div>

            {/* Social Links */}
            <div className="flex flex-col items-center md:items-start">
              <ul className="leading-8 text-center md:text-left">
                <li>
                  <a
                    href="https://www.linkedin.com/company/zenith-studio-in"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-[#FE5416]"
                  >
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a
                    href="https://wa.me/919441878744"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-[#FE5416]"
                  >
                    WhatsApp
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/code_at_zenith/?hl=en"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-[#FE5416]"
                  >
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Address */}
          <div className="flex flex-col items-center md:items-end">
            <h3 className="text-4xl md:text-6xl mb-4 md:mr-20 dm-sans-font text-center md:text-left">
              Find us at
            </h3>
            <p className="text-base text-center md:text-left md:w-[15vw]">
              Plot #682, 5th floor, Babukhan Rasheed Plaza, Road No 36, Aditya
              Enclave, Venkatagiri, Jubilee Hills, Hyderabad, Telangana 500033
            </p>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-white mt-8 pt-4 flex flex-col md:flex-row justify-between items-center">
          <div className="mb-2 md:mb-0 text-left">
            © ZENITH Studio / All Rights Reserved.
          </div>
          <div className="flex space-x-4">
            <Link to="#" className="hover:text-[#FE5416]">
              Cookies
            </Link>
            <Link to="#" className="hover:text-[#FE5416]">
              Privacy Policy
            </Link>
            <Link to="#" className="hover:text-[#FE5416]">
              Terms of service
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
