
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/Home';
import NavBar from './component/NavBar';
import Footer from './component/Footer';
import AboutUsPage from './pages/AboutUsPage';
import ServicesPage from './pages/ServicesPage';
import ContactusPage from './pages/ContactusPage';
import ScrollToTop from './lib/ScrollToTop';
import { SplashCursor } from './component/Splash';
import Loader from './component/Preload';
import { useEffect, useState } from 'react';
import BlogPage from './pages/BlogPage';
import CreateBlog from './pages/CreateBlog';
import PerBlogPage from './pages/PerBlogPage';
import AuthPage from './pages/AuthPage';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuth } from './redux/features/authSlice';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch])

  const [preload, setPreload] = useState(true);
  const { currentUser } = useSelector((state) => state.auth)
console.log(currentUser)
  useEffect(() => {
    setTimeout(() => {
      setPreload(false);
    }, 1500);
  }, []);

  if (preload) {
    return <Loader />
  }


  return (
    <Router>
      <ScrollToTop />
      <SplashCursor />
      <NavBar />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutUsPage />} />
        <Route path="/auth" element={currentUser?.isAdmin ? <Navigate to={"/create-blog"} /> : <AuthPage />} />
        <Route path='/services' element={<ServicesPage />} />
        <Route path='/contact-us' element={<ContactusPage />} />
        <Route path='/create-blog' element={!(currentUser?.isAdmin) ? <Navigate to={"/auth"} /> : <CreateBlog />} />
        <Route path='/blog' element={<BlogPage />} />
        <Route path='/blog/:slug' element={<PerBlogPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
