import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { loginUser, signupUser } from '../redux/features/authSlice';
import { useNavigate } from 'react-router-dom';

const AuthPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(true);
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!username || !password) {
            alert('All fields are required.');
            return;
        }
        if (isLogin) {
            try {
                await dispatch(loginUser({ username, password })).unwrap();
                navigate('/');
            } catch (error) {
                console.log(error)
                alert(error.response?.data?.message || 'Unable to log in. Check your credentials.');
            }
        } else {
            try {
                await dispatch(signupUser({ name, username, password })).unwrap();
                navigate('/');
            } catch (error) {
                console.log(error)
                alert(error.response?.data?.message || 'Unable to log in. Check your credentials.');
            }
        }
    };

    const toggleAuthMode = () => {
        setIsLogin((prevMode) => !prevMode);
    };

    return (
        <div className="flex justify-center items-center h-screen bg-black">
            <motion.div
                className="w-full max-w-md p-6 no-splash"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
            >
                <div className="bg-gray-900 shadow-lg rounded-2xl">
                    <div className="p-8">
                        <h2 className="text-2xl font-bold text-center mb-6 text-white">
                            {isLogin ? 'Login' : 'Sign Up'}
                        </h2>
                        <form onSubmit={handleOnSubmit}>
                            {!isLogin && (
                                <div className="mb-4">
                                    <label className="block text-gray-400 text-sm font-bold mb-2">Name</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-400"
                                        placeholder="Enter your name"
                                    />
                                </div>
                            )}
                            <div className="mb-4">
                                <label className="block text-gray-400 text-sm font-bold mb-2">Username</label>
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-400"
                                    placeholder="Enter your username"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-400 text-sm font-bold mb-2">Password</label>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-400"
                                    placeholder="Enter your password"
                                />
                            </div>
                            <button className="w-full py-2 text-white bg-purple-600 hover:bg-purple-700 rounded-lg">
                                {isLogin ? 'Login' : 'Sign Up'}
                            </button>
                        </form>
                        <p className="text-center text-sm text-gray-400 mt-4">
                            {isLogin ? "Don't have an account?" : 'Already have an account?'}{' '}
                            <button onClick={toggleAuthMode} className="text-purple-400 hover:underline focus:outline-none">
                                {isLogin ? 'Sign Up' : 'Login'}
                            </button>
                        </p>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default AuthPage;
