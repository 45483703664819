import axiosInstance from '../../lib/axio';
import { ArrowRight } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Track'

const BlogList = () => {
    const blogIds = useRef(new Set());
    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);

    // Use a Set to track unique blog IDs

    useEffect(() => {
        const fetchBlogs = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(`/blogs?page=${page}&limit=6`);
                if (response.data.success) {
                    const newBlogs = response.data.blogs.filter(blog => !blogIds.current.has(blog._id));

                    // Add the IDs of the new blogs to the Set
                    newBlogs.forEach(blog => blogIds.current.add(blog._id));

                    // Update state with the new blogs
                    setBlogs((prevPosts) => [...prevPosts, ...newBlogs]);

                    // If the fetched blogs are fewer than the limit, we've reached the end

                    setHasMore(response.data.hasMore);

                }
            } catch (error) {
                console.error('Error fetching latest blogs:', error);
            }
            setLoading(false);
        };

        fetchBlogs();
    }, [page]);

    const handleLoadMore = () => {
        if (hasMore) {
            setPage((prevPage) => prevPage + 1);
        }
    };
    const getPlainText = (html) => {
        const doc = new DOMParser().parseFromString(html, "text/html");
        return doc.body.textContent || "";
    };

    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-20 no-splash p-[10vh]">
                {blogs.map((post) => (

                    <article
                        key={post._id}
                        className="mission-cards group rounded-3xl border border-black overflow-hidden transition-all duration-300 transform hover:-translate-y-1"
                    >
                        <Link to={`/blog/${post.slug}`} className="block" state={{ postData: post }}>
                            <div className="aspect-w-16 aspect-h-9 relative overflow-hidden p-[2vh]">
                                <img
                                    src={post.imageUrl}
                                    alt={post.title}
                                    className="w-full h-[60vh] object-cover transform group-hover:scale-[1.04] rounded-3xl transition-transform duration-500"
                                />
                            </div>
                            <div className="p-8 flex flex-col justify-end">
                                <h2 className="text-[2.5rem] font-bold text-gray-100 mb-4 group-hover:text-orange-400 transition-colors duration-200">
                                    {post.title}
                                </h2>
                                <p className="text-white mb-6 leading-relaxed">
                                    {getPlainText(post.content).split(" ").slice(0, 20).join(" ") + "..."}
                                </p>
                                <div className="flex items-center justify-between">
                                    <span className="text-sm font-medium text-gray-400 bg-gray-800/50 px-3 py-1 rounded-full">
                                        {Math.ceil(post.readTime / 60)} min read
                                    </span>
                                    <span className="flex items-center text-orange-400 font-medium">
                                        Read More
                                        <ArrowRight className="ml-2 h-8 w-8 transition-transform duration-200 bg-orange-500 text-black rounded-full" />
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </article>

                ))}
            </div>


            {/* Show Loading Spinner */}
            {loading && (
                <div className="flex justify-center items-center my-10">
                    <Loader />
                </div>
            )}

            {hasMore && !loading && (
                <button
                    onClick={handleLoadMore}
                    className="no-splash m-8 px-6 py-3 text-lg font-semibold text-white bg-[#FE5416] rounded-lg hover:bg-orange-800 transition-all relative left-[45%]"
                >
                    Show More
                </button>
            )}
        </div>
    );
};

export default BlogList;
