import React, { useState } from 'react';
import { Send, ChevronDown, Loader, Mail, CheckCircle } from 'lucide-react';
import axiosInstance from '../lib/axio';

function ContactusPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    details: '',
    service: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Basic email validation using regex
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Basic phone validation: ensure at least 10 digits after removing non-digit characters.
  const validatePhone = (phone) => {
    const digits = phone.replace(/\D/g, '');
    return digits.length >= 10;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({}); // Clear previous errors
    setIsSubmitting(true);

    // Validate email and phone before submission
    const newErrors = {};
    if (!validateEmail(formData.email)) {
      newErrors.email = 'Please enter a valid email.';
    }
    if (!validatePhone(formData.phone)) {
      newErrors.phone = 'Please enter a valid phone number.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSubmitting(false);
      return;
    }

    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1500));

    setIsSubmitted(true);

    try {
      await axiosInstance.post('/contact-us', { formData });
      // console.log(response);
    } catch (error) {
      console.log(error);
    }

    setIsSubmitting(false);
    // Reset form after submission
    setFormData({
      name: '',
      email: '',
      phone: '',
      details: '',
      service: ''
    });
  };

  return (
    <div className="min-h-[160vh] bg-black flex flex-col items-center justify-evenly p-4 relative overflow-hidden">
      <div className="flex flex-col justify-center items-center md:mt-0 mt-[15vh]">
        <h1 className="flex md:text-8xl md:font-bold text-[#FF926A] text-[8vh] opacity-20">
          Contact <span className='md:block hidden ml-4'>{'Us'}</span>
        </h1>
        <h2 className="md:text-5xl md:font-semibold text-[#FE5416] text-[6vh] md:-translate-y-9 -translate-y-[7vh]">
          Connect
        </h2>
      </div>

      <div className="w-full max-w-6xl flex md:flex-col flex-col-reverse lg:flex-row gap-16 items-center relative z-10 md:mt-[10vh] mt-[2vh]">
        <div className="flex-1 w-full">
          <div className="no-splash md:backdrop-blur-lg md:p-10 p-4 md:py-10 py-12 rounded-2xl shadow-2xl border mission-cards border-[#333]/50 relative overflow-hidden group transition-all duration-300">
            {isSubmitted ? (
              <div className="flex flex-col items-center justify-center md:py-16 space-y-8 md:animate-fade-in">
                {/* Success animation container */}
                <div className="relative">
                  {/* Outer ring animation */}
                  <div className="absolute inset-0 rounded-full bg-gradient-to-r from-[#ff4d00] to-[#ff8a00] animate-ping opacity-20" />
                  {/* Inner success circle */}
                  <div className="relative w-24 h-24 rounded-full bg-gradient-to-br from-[#ff4d00] to-[#ff8a00] flex items-center justify-center transform hover:scale-105 transition-transform duration-300">
                    <CheckCircle className="w-12 h-12 text-white" />
                  </div>
                </div>

                {/* Thank you message */}
                <div className="text-center space-y-4">
                  <h3 className="text-4xl font-bold text-white">Message Received!</h3>
                  <div className="h-1 w-20 mx-auto bg-gradient-to-r from-[#ff4d00] to-[#ff8a00] rounded-full" />
                </div>

                {/* Response time indicator */}
                <div className="bg-[#252525]/50 rounded-xl p-6 max-w-md mx-auto space-y-4">
                  <div className="flex items-center gap-3 text-[#ff4d00]">
                    <Mail className="w-5 h-5" />
                    <span className="font-medium">Expected Response Time</span>
                  </div>
                  <p className="text-gray-400 leading-relaxed">
                    Our team will carefully review your project details and get back to you within 24 hours with a personalized response.
                  </p>
                </div>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6 relative">
                <div className="space-y-2">
                  <label htmlFor="name" className="text-white/70 text-sm font-medium pl-1">Your Name</label>
                  <input
                    id="name"
                    type="text"
                    placeholder="John Doe"
                    className="w-full bg-[#252525]/50 border border-[#333]/80 rounded-lg px-4 py-3.5 text-white placeholder:text-white/30 focus:outline-none focus:border-[#ff4d00]/50 focus:ring-2 focus:ring-[#ff4d00]/20 transition-all duration-300"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    required
                  />
                </div>

                <div className="space-y-2">
                  <label htmlFor="email" className="text-white/70 text-sm font-medium pl-1">Your Email</label>
                  <input
                    id="email"
                    type="email"
                    placeholder="john@example.com"
                    className="w-full bg-[#252525]/50 border border-[#333]/80 rounded-lg px-4 py-3.5 text-white placeholder:text-white/30 focus:outline-none focus:border-[#ff4d00]/50 focus:ring-2 focus:ring-[#ff4d00]/20 transition-all duration-300"
                    value={formData.email}
                    onChange={(e) => {
                      setFormData({ ...formData, email: e.target.value });
                      if (errors.email) setErrors({ ...errors, email: '' });
                    }}
                    required
                  />
                  {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                </div>

                <div className="space-y-2">
                  <label htmlFor="phone" className="text-white/70 text-sm font-medium pl-1">Your Phone Number</label>
                  <input
                    id="phone"
                    type="tel"
                    placeholder="123-456-7890"
                    className="w-full bg-[#252525]/50 border border-[#333]/80 rounded-lg px-4 py-3.5 text-white placeholder:text-white/30 focus:outline-none focus:border-[#ff4d00]/50 focus:ring-2 focus:ring-[#ff4d00]/20 transition-all duration-300"
                    value={formData.phone}
                    onChange={(e) => {
                      setFormData({ ...formData, phone: e.target.value });
                      if (errors.phone) setErrors({ ...errors, phone: '' });
                    }}
                    required
                  />
                  {errors.phone && <p className="text-red-500 text-xs mt-1">{errors.phone}</p>}
                </div>

                <div className="space-y-2">
                  <label htmlFor="details" className="text-white/70 text-sm font-medium pl-1">Project Details</label>
                  <textarea
                    id="details"
                    placeholder="Tell us about your project..."
                    rows={4}
                    className="w-full bg-[#252525]/50 border border-[#333]/80 rounded-lg px-4 py-3.5 text-white placeholder:text-white/30 focus:outline-none focus:border-[#ff4d00]/50 focus:ring-2 focus:ring-[#ff4d00]/20 transition-all duration-300 resize-none"
                    value={formData.details}
                    onChange={(e) => setFormData({ ...formData, details: e.target.value })}
                    required
                  />
                </div>

                <div className="space-y-2 relative">
                  <label htmlFor="service" className="text-white/70 text-sm font-medium pl-1">Service Type</label>
                  <div className="relative">
                    <select
                      id="service"
                      className="w-full bg-[#252525]/50 border text-white border-[#333]/80 rounded-lg px-4 py-3.5 appearance-none focus:outline-none focus:border-[#ff4d00]/50 focus:ring-2 focus:ring-[#ff4d00]/20 transition-all duration-300"
                      value={formData.service}
                      onChange={(e) => setFormData({ ...formData, service: e.target.value })}
                      required
                    >
                      <option value="" className="bg-[#1f1f1f] text-gray-400">Select the Service</option>
                      <option value="Custom Web Development" className="bg-[#1f1f1f] text-white">Custom Web Development</option>
                      <option value="UI/UX Design" className="bg-[#1f1f1f] text-white">UI/UX Design</option>
                      <option value="Landing Pages & Promo Sites" className="bg-[#1f1f1f] text-white">Landing Pages & Promo Sites</option>
                      <option value="Website Redesign & Rebuild" className="bg-[#1f1f1f] text-white">Website Redesign & Rebuild</option>
                      <option value="UI/UX Consultation" className="bg-[#1f1f1f] text-white">UI/UX Consultation</option>
                      <option value="Ongoing Support & Maintenance" className="bg-[#1f1f1f] text-white">Ongoing Support & Maintenance</option>
                    </select>
                    <ChevronDown className="absolute right-4 top-1/2 -translate-y-1/2 text-white/50 pointer-events-none w-4 h-4" />
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full bg-gradient-to-r from-[#ff4d00] to-[#ff8a00] text-white py-4 rounded-lg font-medium hover:from-[#ff8a00] hover:to-[#ff4d00] transition-all duration-300 flex items-center justify-center gap-2 group shadow-lg shadow-[#ff4d00]/10 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? (
                    <>
                      <Loader className="w-5 h-5 animate-spin" />
                      <span>Sending...</span>
                    </>
                  ) : (
                    <>
                      <span>Send Message</span>
                      <Send className="w-4 h-4 transform group-hover:translate-x-1 transition-transform duration-300" />
                    </>
                  )}
                </button>
              </form>
            )}
          </div>
        </div>

        <div className="flex-1 text-white lg:pl-8">
          <h2 className="md:text-5xl md:font-bold mb-6 leading-tight text-[5vh]">
            Let's begin your journey to{' '}
            <span className="bg-gradient-to-r from-[#ff4d00] to-[#ff8a00] bg-clip-text text-transparent">
              Digital success.
            </span>
          </h2>

          <div className="mt-12 space-y-6">
            <div className="flex items-center gap-4 text-lg">
              <div className="w-12 h-12 rounded-full bg-gradient-to-br from-[#ff4d00]/20 to-[#ff8a00]/20 flex items-center justify-center">
                <Send className="text-[#ff4d00] w-5 h-5" />
              </div>
              <p className="text-gray-400">business@zenithstudio.in</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactusPage;
