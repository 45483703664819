import { useState, useRef } from 'react';
import axiosInstance from '../lib/axio';
import Editor from '../component/Editor';

const CreateBlog = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });

  const [dragActive, setDragActive] = useState(false);
  const hiddenFileInput = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ text: '', type: '' });

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    formData.append('image', image);

    try {
      await axiosInstance.post('/blogs/create', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setLoading(false);
      setMessage({ text: 'Blog created successfully!', type: 'success' });
      setTitle('');
      setContent('');
      setImage(null);
      setTimeout(() => setMessage({ text: '', type: '' }), 1500);
    } catch (err) {
      setLoading(false);
      setMessage({ text: 'Error creating blog. Please try again later.', type: 'error' });
      console.error(err);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setImage(e.dataTransfer.files[0]);
    }
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <div className="min-h-screen bg-black text-white py-10 px-4">
      <div className="max-w-[90%] mx-auto bg-gray-900 shadow-lg rounded-lg p-8 mt-[10vh]">
        <h1 className="text-3xl font-bold text-center mb-8">Create Blog</h1>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="mt-1 block w-full p-3 border border-gray-700 bg-gray-800 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-base text-white"
              placeholder="Enter your blog title"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium">Main Content</label>
            <Editor content={content} onUpdate={setContent} />
          </div>

          <div>
            <label className="block text-sm font-medium">Image</label>
            <div
              className={`relative mt-1 flex items-center justify-center w-full text-sm border-2 border-dashed rounded-md p-6 cursor-pointer
              ${dragActive ? 'border-purple-500 bg-gray-700' : 'border-gray-700 bg-gray-800'}
            `}
              onClick={handleClick}
              onDragEnter={handleDrag}
              onDragOver={handleDrag}
              onDragLeave={handleDrag}
              onDrop={handleDrop}
            >
              {image ? (
                <p className="text-center">{image.name}</p>
              ) : (
                <p className="text-center text-gray-400">Drag & drop an image here, or click to select one</p>
              )}
            </div>

            <input
              type="file"
              name="image"
              accept="image/*"
              ref={hiddenFileInput}
              style={{ display: 'none' }}
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setImage(e.target.files[0]);
                }
              }}
            />

            {loading && <p className="text-sm text-purple-400 mt-2">Uploading image...</p>}
          </div>

          <button
            type="submit"
            disabled={loading}
            className={`w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white transition-colors duration-150 ${
              loading ? 'bg-purple-500 opacity-50 cursor-not-allowed' : 'bg-purple-600 hover:bg-purple-700'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500`}
          >
            {loading ? 'Creating...' : 'Create Blog'}
          </button>
        </form>

        {message.text && (
          <div className={`mt-4 p-3 rounded-md text-sm ${message.type === 'success' ? 'bg-green-600' : 'bg-red-600'}`}>
            {message.text}
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateBlog;
