import { ArrowLeft, Calendar, Clock } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';

const PerBlogPage = () => {
  const location = useLocation();
  const {slug} = useParams();

  const statePost = location.state && location.state.postData;
  const [post ,setPost] = useState(statePost || null);

  useEffect(() => {
    // If post data is not in state, fetch it from the API
    if (!post) {
      setLoading(true);
      axios
        .get(`/blogs/${slug}`)
        .then((response) => {
          setPost(response.data);
        })
        .catch((err) => {
          console.log('Error fetching blog data.',err);
        });
    }
  }, [post, slug]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };


  return (
    <article className="max-w-7xl mx-auto no-splash text-white">
      <Link
        to="/blog" 
        className="inline-flex items-center text-[#FE5416] hover:text-orange-700 mb-8 group mt-[10vh]"
      >
        <ArrowLeft className="h-5 w-5 mr-2 transform group-hover:-translate-x-1 transition-transform duration-200" />
        Back to all posts
      </Link>

      <div className="relative rounded-3xl overflow-hidden mb-10">
        <img
          src={post.imageUrl}
          alt={post.title}
          className="w-full h-[400px] object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/50 to-transparent" />
        
        <div className="absolute bottom-0 left-0 right-0 p-8">
          {/* <div className="mb-4">
            <span className="px-3 py-1 bg-orange-400 text-gray-900 rounded-full text-sm font-medium">
              {post.category}
            </span>
          </div> */}
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-4 text-shadow">
            {post.title}
          </h1>
          
          <div className="flex items-center gap-6 text-gray-300">
            {/* <div className="flex items-center gap-2">
              <img
                src={post.author.avatar}
                alt={post.author.name}
                className="w-10 h-10 rounded-full border-2 border-orange-400"
              />
              <div>
                <div className="font-medium">{post.author.name}</div>
                <div className="text-sm text-gray-400">{post.author.role}</div>
              </div>
            </div> */}
            <div className="flex items-center gap-1 text-sm">
              <Clock className="h-4 w-4" />
              {(post.readTime)/60} min read
            </div>
            <div className="flex items-center gap-1 text-sm">
              <Calendar className="h-4 w-4" />
              {formatDate(post.createdAt)}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-900/50 backdrop-blur-sm rounded-3xl border border-gray-800/50 p-8 md:p-12">
        <div 
          className="prose prose-invert prose-orange max-w-none"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
      </div>
    </article>
  );
}

export default PerBlogPage
