import React from 'react'
import BlogList from '../component/blogpage/BlogList'

const BlogPage = () => {
    return (
        <>
            <div className='text-white flex justify-center'>
                <div className="max-w-7xl  bg-black px-6 flex flex-col items-center justify-center py-[15vh]">
                    <div className=" flex flex-col items-center justify-center !dm-sans-font">
                        <h1 className="md:text-8xl text-[8vh] md:font-bold text-[#FF926A] opacity-20 md:translate-y-0 ">Our Blogs</h1>
                        <h2 className="md:text-6xl  text-[6vh] md:font-medium text-[#FE5416] md:-translate-y-9 -translate-y-[7vh]">Blogs</h2>
                    </div>

                    <div className="md:mt-24 max-w-4xl  text-center">
                        <p className="md:text-xl text-[1.9vh] leading-relaxed text-gray-300 noto-sans-font">
                            Welcome to the Zenith Studio blog, where we venture into the realm of horology, innovation, and craftsmanship. Venture into the tales behind our legendary timepieces, learn about the new watchmaking trends, and gain a behind-the-scenes look at the art and science that make up Zenith. If you're an experienced collector or just entering the world of high-end watches, our blog provides greater insight into the heritage and future of Zenith.
                        </p>
                    </div>
                </div>
            </div>
            <BlogList />
        </>
    )
}

export default BlogPage
