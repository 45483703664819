import React from 'react'
import AboutSection from '../component/aboutUs/AboutSection'
import MissionSection from '../component/aboutUs/MissionSection'
import Team from '../component/aboutUs/Team'

const AboutUsPage = () => {
  return (
    <>
      <AboutSection />
      <MissionSection />
      <Team />
    </>
  )
}

export default AboutUsPage
