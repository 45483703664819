// ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (pathname == '/about' && hash == '#team') {
      window.scrollTo(0, 0);
      window.scrollTo({
        top: window.innerHeight * 2.2,
        behavior: "smooth"
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname,hash]);

  return null; // This component doesn't render anything visible.
}

export default ScrollToTop;
