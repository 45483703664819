import React from 'react'

const AboutSection = () => {
    return (
        <div className="md:min-h-[120vh] min-h-[170vh] noto-sans-font bg-black text-white md:px-20 min-w-[100vw] flex justify-center items-center">
            <div className="  ">
                {/* Title Section */}
                <div className=" flex flex-col items-center justify-center !dm-sans-font md:mt-[20vh] ">
                    <h1 className="md:text-8xl text-[8vh] md:font-bold text-[#FF926A] opacity-20 md:translate-y-0 ">About Us</h1>
                    <h2 className="md:text-6xl  text-[6vh] md:font-medium text-[#FE5416] md:-translate-y-9 -translate-y-[7vh]">About</h2>
                </div>

                {/* Content Section */}
                <div className="flex  md:mt-[10vh] flex-col-reverse md:flex-row">
                    {/* Image placeholder */}
                    <div>
                        <img className='md:min-h-[60vh] md:w-[30vw] p-[4vw] md:p-0' src="https://coworkingers.com/wp-content/uploads/2021/10/Rent-A-desk-Madhapur1-min.jpg" alt="" />
                    </div>

                    {/* Text content */}
                    <div className="space-y-8 md:text-[2.2vh] md:max-w-[40vw] md:px-[5vw] px-[5vw]">
                        <p className=" leading-relaxed">
                            At Zenith Studio, we're convinced that a beautifully designed digital presence is not just about looks—it's about the experience. With expertise in custom website development, UI/UX design, and smooth website migrations, we breathe life into brands with clean, high-performance code. Unlike template-based solutions, our method is based on precision, innovation, and an intimate knowledge of user behavior, so every website we create is as functional as it is visually impressive.
                        </p>

                        <p className=" leading-relaxed">
                            Our capabilities extend beyond development; we provide strategic consultations that enable companies to enhance their online presence with assurance. From a clean corporate website, an energetic e-commerce site, or a full-scale overhaul, we design each project to capture your brand's identity. At Zenith Studio, we don't merely build websites—we build digital experiences that make a lasting impression.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection
